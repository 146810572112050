<template>
<div class="print-itinerary">
    <div class="A4" style="padding: 0mm 10mm; text-align: justify">
        <table>
            <thead><tr><td>
                <!-- <div class="header-space">&nbsp;</div> -->
            </td></tr></thead>

            <tbody><tr><td>
                <div id="pageHost">
                    <div class="content">
                        <div class="p-grid">
                            <div style="width: 14rem; margin-left: 5mm">
                                <img alt="voucher-logo" style="width: 70%" src="../assets/images/logo-on-voucher.png" />
                            </div>

                            <div class="voucher-header" style="width: 30rem; text-align: right">
                                <span style="font-size: 13pt; font-weight: 600; color: #0080cb">Angkor Destination Travel</span>
                                <p>P. O. Box.: 93148 | Siem Reap Angkor.</p>
                                <p>Office No. 629, Phum Mondul I, Svay Dangkum, Siem Reap Krong</p>
                                <p>Hotline: +855 (0)95 800 801 | +855 (0)12 695 295</p>
                                <p>Ph.: +855 (0)63 767 868 | Fax.: +855 (0)63 767 969</p>
                                <p>Email: info@angkordestination.com | www.angkordestination.com</p>
                                <hr style="border: 0; border-top: 2px solid #000; margin-top: 5mm; margin-right: 0mm;">
                            </div>

                            <div class="itinerary-title">
                                {{itineraryTitle}}
                            </div>
                        </div>

                        <div style="margin-bottom: 2mm">
                            <span style="text-decoration: underline; font-weight: 600; color: #0080cb">TOUR OUTLINE</span><br />
                        </div>
                        <div class="p-grid itinerary-outline" v-for="data of itineraryData" :key="data.date">
                            <p class="p-col-fixed" style="margin-right: 5mm">{{data.date.replace(/-/g, '').toUpperCase()}}:</p>  	
                            <p class="p-col">{{data.itin_outline}}</p>
                        </div>
                        
                        <div style="margin-top: 5mm; margin-bottom: 5mm">
                            <span style="text-decoration: underline; font-weight: 600; color: #0080cb">TOUR ITINERARY</span>
                        </div>
                        <div class="p-mt-3 tour-itinerary" v-for="data of itineraryData" :key="data.date">
                            <div class="p-grid">
                                <p class="p-col-fixed">{{data.date.replace(/-/g, '').toUpperCase()}}:</p>  	
                                <p class="p-col">{{data.itin_outline}}</p>
                            </div>
                            <div class="itin-content">
                                <span v-html="data.itin_detail"></span>
                            </div>
                        </div>

                        <div style="color: #000; margin-top: 10mm; text-align: center">
                            <p style="font-size: 9pt; text-decoration: underline">End of service in Cambodia!</p>
                        </div>
                        
                        <table class="itinerary-inclusive">
                            <tr>
                                <td><p style="font-weight: bold">Service Included:</p></td>
                                <td><p style="font-weight: bold">Service Excluded:</p></td>
                            </tr>
                            <tr>
                                <td>
                                    <p><span v-html="itineraryInclusive.inclusive"></span></p>
                                </td>
                                <td>
                                    <p><span v-html="itineraryInclusive.exclusive"></span></p>
                                </td>
                            </tr>
                        </table>

                        <hr style="border: 0; border-top: 1px solid #000; margin-top: 10mm; margin-right: 0mm;">
                        <div style="font-size: 9pt">
                            <span style="text-decoration: underline; font-weight: bold; color: #0080cb">Social helps:</span>
                            <p>Angkor Destination is committed to help the underprivileged students who wish to continue their education at the university and support Health Care Centre in the Local Community by contributing and opening the door to link our clients, customers directly</p>
                            <div style="color: #000; margin-top: 5mm; text-align: center">
                                "GIVING HOPE AND A SMILE TO ANOTHER IS PART OF BEING HUMAN".
                            </div>
                        </div>
                    </div>
                </div>
            </td></tr></tbody>

            <tfoot><tr><td>
                <div class="footer-space">&nbsp;</div>
            </td></tr></tfoot>
        </table>
        <header id="pageHeader">
            <!-- Custom Header Content -->
        </header>
        <footer id="pageFooter">		
            <div class="footer-content">
                <hr style="border: none; border-top: 1px solid #000;">
                <p>P. O. Box.: 93148 | Siem Reap Angkor.</p>
                <p>Hotline: +855 (0)95 800 801 | +855 (0)12 695 295</p>
                <p>Ph.: +855 (0)63 767 868 | Fax.: +855 (0)63 767 969</p>
                <p>E.: info@angkordestination.com | www.angkordestination.com</p>
            </div>
            <div class="numberOfPages"></div>
        </footer>
    </div>
</div>
</template>

<script>
import { ref, reactive, onMounted, nextTick } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import BookingService from '../service/BookingService';

export default {
    setup() {
        const store = useStore()
        const route = useRoute()
        const bookingID = route.query.bookingid
        let itineraryTitle = ref('')
        let itineraryData = ref([])
        let itineraryInclusive = reactive({})

        const bookingService = ref(new BookingService())

        store.dispatch('showSideBar', false) 
        store.dispatch('showTopBar', false) 

        onMounted(() => {
            getBookingItinerary()
        })
        
        function getBookingItinerary() {
            if(bookingID != 0) {
                bookingService.value.getItineraryData(bookingID).then(data => {
                    if(!data.errorResponse) {
                        itineraryTitle.value = data.itineraryTitle
                        itineraryData.value = data.itineraryData
                        console.log(data)
                        Object.assign(itineraryInclusive, data.itinInclusive[0])
                        // loadingData.value = false
                        // loadingError.value = false
                        nextTick(() => {
                            document.title = itineraryTitle.value
                            window.print()
                        })
                    } else {
                        // loadingData.value = false
                        // loadingError.value = true
                        // errorCode.value = data.status
                    }
                })
                
            }
        }
                    

        return {
            itineraryData,
            itineraryInclusive,
            itineraryTitle
        }
    }
}
</script>

<style lang="scss" scoped>
.print-itinerary {
    top: 0;
    // display: flex;
	// flex-direction: column;
	// align-items: center;
    background-color: #fff;
	position: absolute;
	width: 100%;
	height: auto;
	z-index: 9999;
    page-break-after: always;
}

p {
    color: #000;
    // line-height: 1.3;
    margin: 0;
}
@page {
	size: A4;
	margin: 12mm 12mm 12mm 12mm;
}
@media print {
	header, .header-space,
	footer, .footer-space {
		display: block !important;
	}
	
	#pageHost {
		width: 100% !important;
	}
	
	.content {
		font-size: 12px;
	}
}
header, .header-space,
footer, .footer-space {
	height: 13mm;
	font-weight: bold;
	width: 100%;
	padding: 0;
	margin: 2mm 0mm 2mm 0mm;
	display: none;
}

header {
	position: fixed;
	top: 0;
}

footer {
	position: fixed;
	bottom: 0;
	width: 100%;
}
.footer-content {
	font-size: 6pt;
	// justify-content: center;
	text-align: center;
    margin-right: 20mm;
    p {
        line-height: 1.2;
        font-weight: 400;
    }
}
#pageHost {	
	// width: 50%;
	justify-content: center;
	margin: auto;
	left: 0;
}
.voucher-header {
    margin-top: 4mm;
    p {
        font-size: 8pt;
    }
}
.itinerary-title {
    margin: 10mm auto 10mm auto; 
    text-align: center;
    font-size: 16pt; 
    font-weight: 600; 
    color: #0080cb;
}
.itinerary-outline p {
    padding: .5rem 0 0 .5rem;
}

.tour-itinerary p {
    padding: .5rem 0 0 .5rem; 
    font-weight: bold
}
.itinerary-inclusive tr,
.itinerary-inclusive td {
    width: 50%;
    vertical-align: top;
}
</style>